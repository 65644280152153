.post-container-mobile {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 50px;
  position: relative; /* Make the container relative to position its children */
}

.post-container {
  display: flex;
  flex-direction: row;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 50px;
  position: relative; /* Make the container relative to position its children */
}
