@import "../../styles/general.css";

.language-select {
  font-family: var(--font-main-title);
  color: var(--secondary-color);
  border-color: aqua;
  background-color: rgba(248, 246, 246, 0.273);
  height: 40px;
  width: 70px;
}

.flag-logo {
  border-radius: 20%;
  max-height: 20px; /* Set a fixed height */
  min-height: 20px;
}
