.custom-longcard-container {
  margin-bottom: 5vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.image-container {
  transition: transform 0.1s ease;
}

.image-container:hover {
  transform: scale(1.05);
}

.image-container img {
  width: 100%;
  height: auto;
}

.description {
  height: 3em; /* Height equivalent to 3 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Maximum of 3 lines */
  -webkit-box-orient: vertical;
}

.card-grid {
  width: 100vw;
  display: flex;
  justify-content: space-around;
}

.card-container {
  margin-bottom: 5vh;
}
