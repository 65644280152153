.about-description {
  width: 80vw;
}

.about-description-mobile {
  width: 95vw;
}

.about-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 50px;
  position: relative; /* Make the container relative to position its children */
}

.about-container-mobile {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 50px;
  position: relative; /* Make the container relative to position its children */
}

.pic-and-overview-super-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.pic-and-overview-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80vw;
}

.pic-and-overview-container-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.profile-pic {
  border-radius: 50%;
  width: 50vh;
  height: 50vh;
  margin-top: -20px;
  margin-right: 20px;
}

.profile-pic-mobile {
  border-radius: 50%;
  width: 50%;
  height: auto;
  margin-top: -20px;
  margin-bottom: 20px;
}

.left-menu-about {
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  width: 70%;
  height: 100%;
  margin-left: 20px;
}

.left-menu-about-mobile {
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  width: 90%;
  height: 100%;
  margin-left: 20px;
}

.text-overview {
  font-size: 17px;
  font-family: "Roboto", sans-serif;
}

.social-icons {
  display: flex;
  justify-content: right;
}

.content-about-extended {
  display: "flex";
  flex-direction: row;
  justify-content: left;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  margin-top: 20px;
  color: white;
}

.bold {
  font-family: "Roboto", sans-serif;
  font-weight: "bold";
}
