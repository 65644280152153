.stories-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 95vw;
}

.card-container {
  margin-bottom: 5vh;
}

.card-grid {
  width: 100vw;
  display: flex;
  justify-content: space-around;
}

.filters-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin-bottom: 20px;
}

.filters-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.no-story-found {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  color: white;
  margin-top: 70px;
  margin-bottom: 50px;
}

.text-stories {
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.map-stories-title {
  font-size: 25px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
