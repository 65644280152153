.markdown > * {
  all: revert;
}

.content {
  font-family: "Roboto", sans-serif;
  line-height: 1.2;
  padding: 3vw;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  --font-main-title: "Roboto";
  --font-title: "Roboto";
  text-align: left;
}
