@import "../../styles/general.css";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--font-main-title);
}

header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 1001;
  /*position: fixed;*/
  top: 0;
  left: 0;
  width: 100%;
  /* padding: 0 2rem; */
  /* background-color: var(--primary-color); */
  background: linear-gradient(
    to bottom,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  color: var(--secondary-color);
}

.header::before {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 2.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 1;
}

nav a {
  margin: 0 1rem;
  color: var(--secondary-color);
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--secondary-color);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
  z-index: 1002;
}

header div,
nav {
  display: flex;
  align-items: center;
  width: 100%; /* Add this line to make sure the entire header width is utilized */
}

.logo {
  height: 12vw;
  width: 12vw;
  max-height: 100px;
  max-width: 100px;
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

.addresses-link {
  font-size: 17px;
  font-weight: bold;
}

.addresses-link-horizontal {
  max-width: 20vw;
  font-size: 17px;
  font-weight: bold;
}

.addresses-link:hover {
  text-decoration: underline;
}

.left-part {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* Change from 'start' to 'flex-start' */
  flex: 1; /* Add this line to make the left part take as much space as possible */
  max-width: 100px;
}

.central-part {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1; /* Add this line to make the central part take as much space as possible */
}

.central-right-part {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; /* Change from 'end' to 'flex-end' */
  flex: 1; /* Add this line to make the right part take as much space as possible */
}

.right-part {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; /* Change from 'end' to 'flex-end' */
  flex: 1; /* Add this line to make the right part take as much space as possible */
  max-width: 100px;
  margin-right: 10px;
}

.social-icons-container {
  display: flex;
  flex-direction: row;
  min-width: 0vw;
}

.social-icons-container:hover {
  color: var(--fourth-color);
  cursor: pointer;
}

.header-mobile {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  flex: 1;
  padding: 20px;
}

.left-header-mobile {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex: 1;
}

.logo-mobile {
  height: 50px;
  max-height: 100px;
}

.logo-mobile-animated-menu {
  width: 130px;
  height: 130px;
}

.logo-mobile-menu {
  height: 100px;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  z-index: 999;
}

.mobile-menu-top {
  position: absolute;
  top: 80vh;
  left: 0;
}

.mobile-menu-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  max-height: 100vh;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    z-index: 1002;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--primary-color);
    transition: 1s;
    z-index: 1001;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    z-index: 1001;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 1002;
  }

  nav a {
    font-size: 1.5rem;
  }
}
