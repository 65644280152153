@font-face {
  font-family: "JuliusSansOne-Regular";
  src: url("../assets/fonts/JuliusSansOne-Regular.ttf");
}

:root {
  --primary-color: #262e48;
  --secondary-color: #e6e9eb;
  --third-color: #8682e1;
  --fourth-color: #4579c4;
  --font-main-title: "JuliusSansOne-Regular";
  --font-title: "Roboto";
}

.main-title {
  font-family: var(--font-main-title);
  color: var(--secondary-color);
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Add this line to disable horizontal scrolling */
  overflow-x: hidden;
  background-color: var(--primary-color);
}

.bg {
  background-color: var(--primary-color);
}

.markdown > * {
  all: revert;
}

.main-container-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 3rem; /* Adjust the base font size as needed */
  color: white;
  font-weight: bold;
  margin-top: 6vh;
  margin-bottom: 6vh;
}

.shade-title {
  background: radial-gradient(
    circle at center,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  color: #fff; /* Text color */
  padding: 10px; /* Add padding for better visibility */
  border-radius: 8px; /* Add rounded corners for better aesthetics */
  font-size: 4vw;
  margin-top: 75px;
}

/* Media query for smaller screens, like mobile devices */
@media (max-width: 768px) {
  .title {
    font-size: 2rem;
    margin-top: 1vh; /* Adjust the font size for smaller screens */
    margin-bottom: 6vh;
  }
}
