@import "./general.css";

/* general.css */

.background {
  background-color: var(--primary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background-image {
  background-image: url("../assets/images/image-home.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  box-sizing: border-box; /* Ensure box-sizing property is set appropriately */
}

.logo {
  height: 12vw;
  max-height: 150px;
  margin-top: 20px;
}

.main-title {
  background: radial-gradient(
    circle at center,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  color: #fff; /* Text color */
  padding: 10px; /* Add padding for better visibility */
  border-radius: 8px; /* Add rounded corners for better aesthetics */
  font-size: 4vw;
  margin-top: 75px;
}

.link-buttons {
  height: 110%;
  width: 120%;
  display: flex;
  justify-content: center;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  margin-top: -20px;
}

.button-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}

.video-desktop-home {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  width: 100%;
  background: linear-gradient(
    to right,
    /* Direction of the gradient */ rgba(0, 0, 0, 0) 0%,
    /* Transparent at the left edge */ rgba(0, 0, 0, 1) 30%,
    /* Fully opaque by 30% of the width */ rgba(0, 0, 0, 1) 70%,
    /* Remain opaque until 70% of the width */ rgba(0, 0, 0, 0) 100%
      /* Transparent again at the right edge */
  );
  position: relative; /* Needed if not already positioned */
  overflow: hidden; /* Ensures the gradient does not spill outside the bounds */
}

.description-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.description {
  width: 70%;
  height: auto;
  color: white;
  margin-bottom: 20px;
  font-family: "Roboto";
  font-size: 17px;
}

.description-mobile {
  width: 90%;
  color: white;
  margin-bottom: 20px;
  font-family: "Roboto";
  font-size: 17px;
}

.map-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
/* Media Query for Responsive Design */
@media (max-width: 768px) {
  /* .background {
    padding: 20px;
  } */

  .logo {
    height: 10vw;
  }

  .main-title {
    font-size: 6vw; /* Adjust font size for smaller screens if needed */
  }
}
