@import "../../styles/general.css";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5vw;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  color: white;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center; /* Center vertically */
}

.header-container {
  height: 5vw;
}

.header h1 {
  margin: 0;
  padding: 10px;
}

.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 2.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: -1;
}

.left-side-header {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center; /* Center vertically */
  width: 50vw;
}

.left-side-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; /* Center vertically */
  width: 20vw;
}

.right-side-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center; /* Center vertically */
  width: 50vw;
}

.addresses-link {
  font-size: 17px;
}

.addresses-link:hover {
  text-decoration: underline;
}

.social-icons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 5vw;
}

.social-icons-container:hover {
  color: var(--fourth-color);
  cursor: pointer;
}
