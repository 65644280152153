#root {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 100vw;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin: 20px auto;
}

.custom-card {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  border-radius: 10px;
  padding: 20px;
  height: 250px;
  width: 215px;
  overflow: hidden;
  cursor: pointer;
}

.custom-card.group {
  position: relative;
}

.custom-card.lg-height {
  height: 400px;
}

.custom-card.lg-width {
  width: 350px;
}

/* carousel.css */

/* Add some margin to create space for arrows */
.swiper-button-prev {
  background-color: rgb(255, 255, 255);
  height: 60px;
  width: 60px;
  border-radius: 25%;
  z-index: 99999;
  margin-bottom: 30px;
}
.swiper-button-next {
  background-color: rgb(255, 255, 255);
  height: 60px;
  width: 60px;
  border-radius: 25%;
  z-index: 99999;
  margin-bottom: 30px;
}

.title-card {
  font-size: 20px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  border-radius: 15px;
}

.text-card {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.5);
}

.publication-card {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  font-style: italic;
}
