.parallax {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden; /* Ensure no overflow */
}

.grass-first-layer {
  width: 100%;
  bottom: -15%;
  z-index: 3;
  position: absolute;
}

.grass-second-layer {
  width: 100%;
  bottom: -10%;
  z-index: 2;
  position: absolute;
}

.mountains-and-lake {
  width: 100%;
  bottom: 17%;
  z-index: 1;
  position: absolute;
}

.stars {
  position: absolute; /* Ensure absolute positioning */
  width: 100%;
  top: 0; /* Set top to 0 to avoid initial gap */
  bottom: 10%;
  position: absolute;
}

/* Rest of your existing CSS remains unchanged */

.main-title {
  background: radial-gradient(
    circle at center,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(241, 7, 7, 0) 100%
  );
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  font-size: 4vw;
  margin-top: 5vw; /* Adjust as needed */
}

.background-image-mobile {
  background-image: url("../../assets/images/parallax/mobile-background.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
  position: relative; /* Ensure positioning */
}

.logo-mobile-parallax {
  height: 150px;
  width: 150px;
}

.navbar-container {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.quote {
  margin-top: "-180px";
  position: "absolute";
  z-index: 2000;
  font-size: "25px";
  color: "white";
  text-align: "center";
  display: flex;
  flex-direction: center;
}

.background-quote {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: -180px;
  z-index: 2000;
  font-size: 25px;
  color: white;
  font-style: italic;
  font-family: "Times New Roman", serif; /* Ensure the font-family is properly formatted */
}

.bottom-quote-background {
  width: 100%;
  height: 200px;
  margin-top: -150px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    /* Transparent at the very top */ rgba(0, 0, 0, 1) 30%,
    /* Start becoming opaque */ rgba(0, 0, 0, 1) 70%,
    /* Fully opaque in the middle */ rgba(0, 0, 0, 0) 100%
      /* Transparent again at the bottom */
  );
  position: relative;
  overflow: hidden;
  z-index: 1999;
}
