.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.not-found-picture {
  width: 20vw;
  border-radius: 50%;
  justify-content: center;
}

.not-found-picture-mobile {
  width: 80vw;
  border-radius: 50%;
  justify-content: center;
}

.link-buttons {
  height: 110%;
  width: 120%;
  display: flex;
  align-items: center;
}
