@import "./general.css";

.story-container {
  display: flex;
  flex-direction: row;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 50px;
  position: relative; /* Make the container relative to position its children */
}

.story-container-mobile {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 50px;
  position: relative; /* Make the container relative to position its children */
}

.story-left-part {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  z-index: 1; /* Ensure that the text appears above the background */
}

.story-right-part {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  z-index: 1; /* Ensure that the text appears above the background */
}

.background-picture {
  width: 100%; /* Make the image cover the entire container */
  height: 100%; /* Make the image cover the entire container */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  position: absolute; /* Position the image absolutely within the container */
  top: 0;
  left: 0;
  z-index: 0; /* Ensure that the background is behind the text */
}

.loading-picture {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Make the image cover the entire container */
  height: 100%; /* Make the image cover the entire container */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  position: absolute; /* Position the image absolutely within the container */
  background-color: "#3b4257";
  top: 0;
  left: 0;
}

.dark-shade {
  width: 100%; /* Make the image cover the entire container */
  height: 100%; /* Make the image cover the entire container */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  position: absolute; /* Position the image absolutely within the container */
  top: 0;
  left: 0;
  z-index: 1; /* Ensure that the background is behind the text */
  height: 120px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 100%);
}

.dark-shade-bottom-down {
  width: 100%;
  height: 120px; /* Adjust the height as needed */
  position: absolute;
  z-index: 0;
  top: 100%; /* Position it below the image */
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    var(--primary-color) 100%
  );
}

.dark-shade-bottom-up {
  width: 100%;
  height: 120px; /* Adjust the height as needed */
  margin-top: -120px;
  position: absolute;
  z-index: 0;
  top: 100%; /* Position it below the image */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    var(--primary-color) 100%
  );
}

.left-menu {
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 10px;
}

.side-stories-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.footer-story-buttons {
  display: flex;
  justify-content: center;
  height: 50px;
  width: 100%;
}

.button-previous-story {
  display: flex;
  height: 100%;
  width: 50%;
  justify-content: start;
  margin-left: 5vw;
}

.button-next-story {
  display: flex;
  height: 100%;
  width: 50%;
  justify-content: end;
  margin-right: 5vw;
}

.footer-stories-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.shade-buttons {
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 15%,
    transparent 30%,
    transparent 70%,
    rgba(0, 0, 0, 0.5) 85%,
    rgba(0, 0, 0, 0.5) 100%
  );
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  font-size: 4vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.link-buttons {
  height: 110%;
  width: 120%;
  display: flex;
  align-items: center;
}

@media (max-width: 1025px) {
  .story-left-part {
    width: 100%;
  }
}
